
import { Activity, Owner } from "@/interfaces";
import Vue, { PropType } from "vue";

import MyEditor from "@/components/activity/MyEditor.vue";

import http from "@/http";

import { mdiPlus } from "@mdi/js";

export default Vue.extend({
  components: {
    MyEditor,
  },
  props: {
    owner: {
      type: Object as PropType<Owner>,
      required: true,
    },
  },
  computed: {
    activity(): Activity {
      return {
        content: "",
        activity_date: "",
        category: "",
        proposals: [],
      };
    },
    icon() {
      return {
        mdiPlus,
      };
    },
  },
  methods: {
    async save(e: any) {
      const url = `owners/${this.owner.id}/activities`;
      const { data } = await http.post(url, {
        ...e,
      });

      await this.$router.push(`/owner/${this.owner.id}/activities`);
    },
  },
});
